import React from 'react';
import styles from './footer.module.scss';

function Footer() {
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.description}>
          <div className={styles.logo}>Турецкий массаж</div>
          <div>© 2024</div>
        </div>
        <div className={styles.text}>Массаж имеет ряд противопоказаний, перед тем как записаться проконсультируйтесь с доктором.</div>
      </div>
    </div>
  );
}

export default Footer;
