import React from 'react';
import Header from '../header/header';
import About from '../about/about';
import Services from '../services/services';
import Licenses from '../licenses/licenses';
import Reviews from '../reviews/reviews';
import Contacts from '../contacts/contacts';
import Video from '../video/video';
import Appointment from '../appointment/appointment';
import ScrollToTop from 'react-scroll-to-top';
import Footer from '../footer/footer';
import styles from './main-page.module.scss';

function MainPage() {
  return (
    <div className={styles.wrapper}>
      <Header />
      <main>
        <About />
        <Services />
        <Video />
        <Reviews />
        <Licenses />
        <Contacts />
        <Appointment />
        <ScrollToTop smooth className={styles.scroll} />
      </main>
      <Footer />
    </div>
  );
}

export default MainPage;
