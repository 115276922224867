import React, { useState } from 'react';
import cn from 'classnames';
import styles from './header.module.scss';

function Header() {

  const [isNavOpen, setIsNavOpen] = useState(false);

  const handleButtonClick = (evt) => {
    evt.preventDefault();
    setIsNavOpen(!isNavOpen);
  };

  return (
    <header className={styles.container}>
      <div className={styles.header_top_wrapper}>
        <div className={styles.header_top}>
          <div className={styles.logo}>Турецкий массаж</div>
          <div className={styles.socials}>
            <ul className={styles.list}>
              <li className={styles.item_social}>
                <a href="https://t.me/mustimkay" target="_blank" className={styles.social_link} rel="noreferrer">
                  <span className='visually-hidden'>telegram</span>
                  <svg fill="#FFFFFF" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 48 48" width="30px" height="30px"><path d="M39.175,10.016c1.687,0,2.131,1.276,1.632,4.272c-0.571,3.426-2.216,14.769-3.528,21.83 c-0.502,2.702-1.407,3.867-2.724,3.867c-0.724,0-1.572-0.352-2.546-0.995c-1.32-0.872-7.984-5.279-9.431-6.314 c-1.32-0.943-3.141-2.078-0.857-4.312c0.813-0.796,6.14-5.883,10.29-9.842c0.443-0.423,0.072-1.068-0.42-1.068 c-0.112,0-0.231,0.034-0.347,0.111c-5.594,3.71-13.351,8.859-14.338,9.53c-0.987,0.67-1.949,1.1-3.231,1.1 c-0.655,0-1.394-0.112-2.263-0.362c-1.943-0.558-3.84-1.223-4.579-1.477c-2.845-0.976-2.17-2.241,0.593-3.457 c11.078-4.873,25.413-10.815,27.392-11.637C36.746,10.461,38.178,10.016,39.175,10.016 M39.175,7.016L39.175,7.016 c-1.368,0-3.015,0.441-5.506,1.474L33.37,8.614C22.735,13.03,13.092,17.128,6.218,20.152c-1.074,0.473-4.341,1.91-4.214,4.916 c0.054,1.297,0.768,3.065,3.856,4.124l0.228,0.078c0.862,0.297,2.657,0.916,4.497,1.445c1.12,0.322,2.132,0.478,3.091,0.478 c1.664,0,2.953-0.475,3.961-1.028c-0.005,0.168-0.001,0.337,0.012,0.507c0.182,2.312,1.97,3.58,3.038,4.338l0.149,0.106 c1.577,1.128,8.714,5.843,9.522,6.376c1.521,1.004,2.894,1.491,4.199,1.491c2.052,0,4.703-1.096,5.673-6.318 c0.921-4.953,1.985-11.872,2.762-16.924c0.331-2.156,0.603-3.924,0.776-4.961c0.349-2.094,0.509-4.466-0.948-6.185 C42.208,7.875,41.08,7.016,39.175,7.016L39.175,7.016z"/></svg>
                </a>
              </li>
              <li className={styles.item_social}>
                <a href="https://www.instagram.com/mustafamassage/" target="_blank" className={styles.social_link} rel="noreferrer">
                  <span className='visually-hidden'>instagram</span>
                  <svg fill="#ffffff" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" width="30px" height="30px">    <path d="M 8 3 C 5.243 3 3 5.243 3 8 L 3 16 C 3 18.757 5.243 21 8 21 L 16 21 C 18.757 21 21 18.757 21 16 L 21 8 C 21 5.243 18.757 3 16 3 L 8 3 z M 8 5 L 16 5 C 17.654 5 19 6.346 19 8 L 19 16 C 19 17.654 17.654 19 16 19 L 8 19 C 6.346 19 5 17.654 5 16 L 5 8 C 5 6.346 6.346 5 8 5 z M 17 6 A 1 1 0 0 0 16 7 A 1 1 0 0 0 17 8 A 1 1 0 0 0 18 7 A 1 1 0 0 0 17 6 z M 12 7 C 9.243 7 7 9.243 7 12 C 7 14.757 9.243 17 12 17 C 14.757 17 17 14.757 17 12 C 17 9.243 14.757 7 12 7 z M 12 9 C 13.654 9 15 10.346 15 12 C 15 13.654 13.654 15 12 15 C 10.346 15 9 13.654 9 12 C 9 10.346 10.346 9 12 9 z"/></svg>
                </a>
              </li>
            </ul>
          </div>
          <div className={styles.phones}>
            <a href="tel:+79858268141" className={styles.phone}>+7 (985) 826-81-41</a>
            <p className={styles.text}>Мустафа</p>
          </div>
          <button
            className={isNavOpen ? cn(styles.menu_button, styles.menu_button_open) : styles.menu_button}
            onClick={handleButtonClick}
            aria-label="Открыть меню"
          >
            <span className="visually-hidden">Открыть меню</span>
          </button>
        </div>
        <nav className={isNavOpen ? cn(styles.nav, styles.nav_opened) : cn(styles.nav, styles.nav_closed)}>
          <ul className={cn(styles.list_mobile)}>
            <li className={styles.item}>
              <a className={styles.link} href="#about">
              О докторе
              </a>
            </li>
            <li className={styles.item}>
              <a className={styles.link} href="#services">
              Услуги
              </a>
            </li>
            <li className={styles.item}>
              <a className={styles.link} href="#certificates">
              Сертификаты
              </a>
            </li>
            <li className={styles.item}>
              <a className={styles.link} href="#reviews">
              Отзывы
              </a>
            </li>
            <li className={styles.item}>
              <a className={styles.link} href="#contacts">
              Контакты
              </a>
            </li>
          </ul>
          <div className={cn(styles.socials, styles.socials_mobile)}>
            <ul className={styles.list}>
              <li className={styles.item_social}>
                <a href="https://t.me/mustimkay" target="_blank" className={styles.social_link} rel="noreferrer">
                  <span className='visually-hidden'>telegram</span>
                  <svg fill="#FFFFFF" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 48 48" width="30px" height="30px"><path d="M39.175,10.016c1.687,0,2.131,1.276,1.632,4.272c-0.571,3.426-2.216,14.769-3.528,21.83 c-0.502,2.702-1.407,3.867-2.724,3.867c-0.724,0-1.572-0.352-2.546-0.995c-1.32-0.872-7.984-5.279-9.431-6.314 c-1.32-0.943-3.141-2.078-0.857-4.312c0.813-0.796,6.14-5.883,10.29-9.842c0.443-0.423,0.072-1.068-0.42-1.068 c-0.112,0-0.231,0.034-0.347,0.111c-5.594,3.71-13.351,8.859-14.338,9.53c-0.987,0.67-1.949,1.1-3.231,1.1 c-0.655,0-1.394-0.112-2.263-0.362c-1.943-0.558-3.84-1.223-4.579-1.477c-2.845-0.976-2.17-2.241,0.593-3.457 c11.078-4.873,25.413-10.815,27.392-11.637C36.746,10.461,38.178,10.016,39.175,10.016 M39.175,7.016L39.175,7.016 c-1.368,0-3.015,0.441-5.506,1.474L33.37,8.614C22.735,13.03,13.092,17.128,6.218,20.152c-1.074,0.473-4.341,1.91-4.214,4.916 c0.054,1.297,0.768,3.065,3.856,4.124l0.228,0.078c0.862,0.297,2.657,0.916,4.497,1.445c1.12,0.322,2.132,0.478,3.091,0.478 c1.664,0,2.953-0.475,3.961-1.028c-0.005,0.168-0.001,0.337,0.012,0.507c0.182,2.312,1.97,3.58,3.038,4.338l0.149,0.106 c1.577,1.128,8.714,5.843,9.522,6.376c1.521,1.004,2.894,1.491,4.199,1.491c2.052,0,4.703-1.096,5.673-6.318 c0.921-4.953,1.985-11.872,2.762-16.924c0.331-2.156,0.603-3.924,0.776-4.961c0.349-2.094,0.509-4.466-0.948-6.185 C42.208,7.875,41.08,7.016,39.175,7.016L39.175,7.016z"/></svg>
                </a>
              </li>
              <li className={styles.item_social}>
                <a href="https://www.instagram.com/mustafamassage/" target="_blank" className={styles.social_link} rel="noreferrer">
                  <span className='visually-hidden'>instagram</span>
                  <svg fill="#ffffff" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" width="30px" height="30px">    <path d="M 8 3 C 5.243 3 3 5.243 3 8 L 3 16 C 3 18.757 5.243 21 8 21 L 16 21 C 18.757 21 21 18.757 21 16 L 21 8 C 21 5.243 18.757 3 16 3 L 8 3 z M 8 5 L 16 5 C 17.654 5 19 6.346 19 8 L 19 16 C 19 17.654 17.654 19 16 19 L 8 19 C 6.346 19 5 17.654 5 16 L 5 8 C 5 6.346 6.346 5 8 5 z M 17 6 A 1 1 0 0 0 16 7 A 1 1 0 0 0 17 8 A 1 1 0 0 0 18 7 A 1 1 0 0 0 17 6 z M 12 7 C 9.243 7 7 9.243 7 12 C 7 14.757 9.243 17 12 17 C 14.757 17 17 14.757 17 12 C 17 9.243 14.757 7 12 7 z M 12 9 C 13.654 9 15 10.346 15 12 C 15 13.654 13.654 15 12 15 C 10.346 15 9 13.654 9 12 C 9 10.346 10.346 9 12 9 z"/></svg>
                </a>
              </li>
            </ul>
          </div>
          <div className={cn(styles.phones, styles.phones_mobile)}>
            <a href="tel:+79858268141" className={styles.phone}>+7 (985) 826-81-41</a>
            <p className={styles.text}>Мустафа</p>
          </div>
        </nav>
      </div>
      <div className={styles.wrapper}>
        <nav className={styles.nav}>
          <ul className={cn(styles.list, styles.list_nav)}>
            <li className={styles.item}>
              <a className={styles.link} href="#about">
                О докторе
              </a>
            </li>
            <li className={styles.item}>
              <a className={styles.link} href="#services">
                Услуги
              </a>
            </li>
            <li className={styles.item}>
              <a className={styles.link} href="#certificates">
                Сертификаты
              </a>
            </li>
            <li className={styles.item}>
              <a className={styles.link} href="#reviews">
                Отзывы
              </a>
            </li>
            <li className={styles.item}>
              <a className={styles.link} href="#contacts">
                Контакты
              </a>
            </li>
          </ul>
        </nav>
        <div className={styles.nav}>
          <div className={styles.description}>
            <p className={styles.title}>Авторская техника массажа,</p>
            <p className={styles.text_bg}>нацеленная на результат</p>
            <a className={styles.button} href='#appointment'>Записаться на массаж</a>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
