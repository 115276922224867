import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Autoplay, A11y } from 'swiper';
import { useMediaQuery } from 'react-responsive';
import 'swiper/css';
import 'swiper/css/pagination';
import License from '../license/license';
import { licenses } from '../../const';
import styles from './licenses.module.scss';

SwiperCore.use([Autoplay, A11y, Pagination]);

function Licenses() {


  const isDesktop = useMediaQuery({ query: '(min-width: 1200px)' });

  const isTablet = useMediaQuery({
    query: '(min-width: 620px)',
  });

  return (
    <div className={styles.container} id="certificates">
      <div className={styles.wrapper}>
        <h2 className={styles.title}>Дипломы и сертификаты</h2>
        <Swiper
        // eslint-disable-next-line no-nested-ternary
          slidesPerView={isDesktop ? 3 : isTablet ? 2 : 1}
          spaceBetween={30}
          a11y
          loop
          pagination={{ clickable: true }}
        >
          {
            licenses.map((license) => (
              <SwiperSlide key={license.id}>
                <License license={license} />
              </SwiperSlide>
            ))
          }
        </Swiper>
      </div>
    </div>
  );
}

export default Licenses;
