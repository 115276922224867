import React, { useRef, useState } from 'react';
import styles from './appointment.module.scss';

import emailjs from '@emailjs/browser';
import ReCAPTCHA from 'react-google-recaptcha';

function Appointment() {

  const refCaptcha = useRef();

  const form = useRef();

  const [toSend, setToSend] = useState({
    name: '',
    phone: '',
    message: '',
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    const token = refCaptcha.current.getValue();

    const params = {
      ...toSend,
      'g-recaptcha-response': token,
    };

    emailjs.send('service_hv7n5ik', 'template_ewfgeqv', params, '4u1Pq9M_aAih9xiVR', 'g-recaptcha-response')
      .then((result) => {
        console.log('test ok');
        e.target.reset();
        window.grecaptcha.reset();
      }, (error) => {
        console.log('error');
      });
  };

  const handleChange = (e) => {
    setToSend({...toSend, [e.target.name]: e.target.value });
  };

  return (
    <div className={styles.container} id="appointment">
      <div className={styles.wrapper}>
        <div className={styles.wrap}>
          <h2 className={styles.title}>Запишитесь на массаж</h2>
          <span className={styles.border}></span>
          <p className={styles.text}>Оставьте свои контакты и мы перезвоним вам для уточнения времени посещения</p>
          <form ref={form} onSubmit={handleSubmit} className={styles.form} >
            <div className={styles.labels}>
              <label className={styles.label}>
                <input onChange={handleChange} type="text" name="name" placeholder="Имя" aria-label="Имя" className={styles.input} required></input>
              </label>
              <label className={styles.label}>
                <input onChange={handleChange} type="tel" pattern="([\+]*[7-8]{1}\s?[\(]*9[0-9]{2}[\)]*\s?\d{3}[-]*\d{2}[-]*\d{2})" name="phone" placeholder="Телефон" aria-label="Телефон" className={styles.input} required></input>
              </label>
            </div>
            <label>
              <textarea onChange={handleChange} className={styles.textarea} name="message" placeholder="Комментарий" aria-label="Комментарий" rows="5"></textarea>
            </label>
            <div className={styles.captcha}>
              <ReCAPTCHA ref={refCaptcha} sitekey="6LfRGKEiAAAAAEQAZkFIGpUhC_qNmjW-xousrnuz" />
            </div>
            <button className={styles.button} type="submit" aria-label="Отправить">Отправить</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Appointment;
