import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Autoplay, A11y } from 'swiper';
import { useMediaQuery } from 'react-responsive';
import Review from '../review/review';
import { reviews } from '../../const';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import styles from './reviews.module.scss';


SwiperCore.use([Autoplay, A11y, Pagination, Navigation]);

function Reviews() {

  const isMobile = useMediaQuery({
    query: '(max-width: 743px)',
  });

  return (
    <div className={styles.reviews} id="reviews">
      <h2 className={styles.title}>Отзывы</h2>
      <Swiper
        slidesPerView={1}
        a11y
        loop
        navigation={isMobile && true}
        pagination={isMobile ? false : { clickable: true }}
        autoplay={{ 'delay': 10000, 'disableOnInteraction': false }}
      >
        {
          reviews.map((review) => (
            <SwiperSlide key={review.id}>
              <Review review={review} />
            </SwiperSlide>
          ))
        }
      </Swiper>
    </div >

  );
}

export default Reviews;
