import license1 from './images/1l.jpg';
import license2 from './images/2l.jpg';
import license3 from './images/3l.jpg';
import license4 from './images/4l.jpg';
import license5 from './images/5l.jpg';

const services = [
  {
    id: 0,
    name: 'all',
    tabName: 'Массаж всего тела',
    title: 'Массаж всего тела',
    time: '60 мин. / 90 мин.',
    price: 3500,
    priceTwo: 5000,
  },
  {
    id: 1,
    name: 'back',
    tabName: 'Массаж спины',
    title: 'Массаж спины',
    time: '40 мин.',
    price: 2500,
  },
  {
    id: 2,
    name: 'neck',
    tabName: 'Массаж шейно-воротниковой зоны',
    title: 'Массаж шейно-воротниковой зоны',
    time: '20 мин.',
    price: 1500,
  },
  {
    id: 3,
    name: 'foot',
    tabName: 'Массаж стоп',
    title: 'Массаж стоп',
    time: '40 мин.',
    price: 2000,
  },
  {
    id: 4,
    name: 'head',
    tabName: 'Массаж головы',
    title: 'Массаж головы',
    time: '30 мин.',
    price: 1500,
  },
];

const reviews = [
  {
    id: 0,
    date: '24.04.2019',
    name: 'Лариса',
    text: 'Мустафа, большое спасибо за профессиональную работу, внимательное и доброе отношение. Благодаря твоим процедурам наступило значительное облегчение в суставах, увеличилась их подвижность, перестала кашлять. Дай Бог здоровья тебе и твоим родным.',
    className: 'single-line',
  },
  {
    id: 1,
    date: '18.05.2019',
    name: 'Лариса г.Калуга',
    text: 'Мустафа, спасибо тебе огромное за твою замечательную работу.\n Благодаря твоим чутким рукам и внимательному отношению моя спина оживает. Из-за сидячей работы садится нижнекресцовый отдел позвоночника. Спасает только массаж Мастера!\n Желаю тебе развивать свой бизнес, расти в профессии, процветания и благополучия.',
    className: 'breaking-line',
  },
  {
    id: 2,
    date: '08.11.2019',
    name: 'Лилия',
    text: 'Регулярно прохожу курс массажа у Мустафы, всегда остаюсь очень довольна.\n В ноябре этого года почувствовала острую боль в спине. Прошла МРТ, обнаружили три грыжи и 1 протрузие. Снова пришла на массаж к Мустафе. После трех сеансов почувствовала облегчение, после 5 сеансов боль ушла.\n Большое спасибо Мустафе за его квалифицированный труд, умелые руки и доброе сердце.',
    className: 'breaking-line',
  },
  {
    id: 3,
    date: '20.01.2020',
    name: 'Мария',
    text: 'Меня поставил на ноги массажем.\n Мустафа золотые руки. \n У меня был паралич, ходить не могла еле-еле передвигалась, а сейчас хожу как добротный человек. Я очень благодарна',
    className: 'breaking-line',
  },
  {
    id: 4,
    date: '26.03.2020',
    name: 'Ульяна',
    text: 'Хочу от всего сердца поблагодарить Мустафу и его учителя Ахмеда. Два года у меня болело колено, а врачи не могли помочь. Здесь же Мустафа после второго сеанса избавил меня от боли.Я сразу почувствовала положительный эффект массажа и поняла, что мне это необходимо.\n Желаю Мустафе и его Учителю Ахмеду всего самого доброго и светлого, здоровья и успехов в карьере!',
    className: 'breaking-line',
  },
  {
    id: 5,
    date: '26.03.2020',
    name: 'Клара',
    text: 'Мустафа, большое спасибо за профессиональную работу, внимательное и доброе отношение, только массаж спасает меня от болей в спине. Спасибо!!!',
    className: 'single-line',
  },
  {
    id: 6,
    date: '26.03.2020',
    name: 'Елена',
    text: 'Больше пяти лет хожу на массаж к Мустафе. Огромное Спасибо за классный! профессиональный труд. У тебя, Мустафа, золотые руки! Процветания, успехов и клиентов (щедрых). Всех благ!',
    className: 'single-line',
  },
  {
    id: 7,
    date: '28.04.2020',
    name: 'Александр',
    text: 'Большое спасибо Мустафе за его усердную работу. За все сеансы, что я посетил, я увидел настоящий профессионализм и желание помогать людям, оказавшимся в трудной ситуации.\n Мустафа с своим учителем Ахмедом исправили мне искривление позвоночника, за что им огромное спасибо.\n Желаю счастья, здоровья и успехов!!!',
    className: 'breaking-line',
  },
  {
    id: 8,
    date: '24.06.2020',
    name: 'Елена',
    text: 'Имею большой опыт жизненный по тому как делают массаж и в санаториях, и в разных странах и в частных клиниках. Мустафа большой профессионал, который не только мастерски делает массаж, но обладает знаниями анатомии человеческого тела и энергетически чувствует и понимает тело. Большая благодарность и уважение ему, человеку который избавил меня от проблем с шейным хондрозом и проблем с ногами.',
    className: 'single-line',
  },
  {
    id: 9,
    date: '11.07.2020',
    name: 'Нина',
    text: 'От всего сердца благодарю Мустафу за его профессиональную работу.\n Благодаря его массажу чувствую себя хорошо. Нигде не получала такого профессионального массажа, имею ввиду городскую поликлинику.\n Спасибо тебе за доброе сердце и золотые руки. Надеюсь встрече в Люберцах. Желаю счастья, всех благ тебе и твоей семье.',
    className: 'breaking-line',
  },
  {
    id: 10,
    date: '12.07.2020',
    name: 'Юрий',
    text: 'Очень благодарен за то что вернул мне интерес к жизни вылечив казалось бы безнадежную боль в плечевом суставе. Я благодарю Бога за то, что встретился с такими людьми как Мустафа и Ахмед.\n Их работу оцениваю - человеческое отношение и результат!',
    className: 'breaking-line',
  },
  {
    id: 11,
    date: '9.08.2020',
    name: 'Катя Йога',
    text: 'Хочется отметить прекрасную работу Мустафы! Это мастер своего дела! Есть люди - профессионалы от Бога, Мустафа именно такой.\n Его массаж творит чудеса, заставляет людей улыбаться, верить в то, что ты можешь быть здоров! Искренняя благодарность Мустафе! Спасибо за ваши волшебные и целебные руки! Удачи! Процветания!',
    className: 'breaking-line',
  },
  {
    id: 12,
    date: '',
    name: '',
    text: 'Мустафа мастер своего дела! Он врождённый массажист. Знает все тонкости профессионального массажа.\n У меня остеохондроз грудного позвоночника. Всего было пару массажа и я дышу свободно!\n Спасибо что ты есть, что ты золотой массажист!',
    className: 'breaking-line',
  },
];

const licenses = [
  {
    id: 0,
    image: license1,
    tabName: 'Сертификат',
  },
  {
    id: 1,
    image: license2,
    tabName: 'Сертификат',
  },
  {
    id: 2,
    image: license3,
    tabName: 'Сертификат',
  },
  {
    id: 3,
    image: license4,
    tabName: 'Сертификат',
  },
  {
    id: 4,
    image: license5,
    tabName: 'Сертификат',
  },
];

export { services, reviews, licenses };
