import React from 'react';
import PropTypes from 'prop-types';
import styles from './service.module.scss';
import all from '../../images/all.jpg';
import back from '../../images/back.jpg';
import neck from '../../images/neck.jpg';
import foot from '../../images/foot.jpg';
import head from '../../images/head.jpg';


const images = [all, back, neck, foot, head];

function Service({ service }) {

  const { id, tabName, title, time, price, priceTwo } = service;

  return (
    <div className={styles.item}>
      <img className={styles.image} src={images[id]} alt={`${tabName}`} width="533" height="800" />
      <h3 className={styles.title}>{title}</h3>
      <span className={styles.border}></span>
      <p className={styles.time}>{time}</p>
      <div className={styles.prices}>
        <p className={styles.price}><span className={styles.bold}>{price}</span> руб.</p>
        {id === 0 && (
          <p className={styles.price}>&nbsp;/ <span className={styles.bold}>{priceTwo}</span> руб.</p>
        )}
      </div>
    </div>
  );
}

Service.propTypes = {
  service: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    tabName: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    time: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    priceTwo: PropTypes.number,
  }),
};

export default Service;
