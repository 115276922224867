import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Autoplay, A11y } from 'swiper';
import { useMediaQuery } from 'react-responsive';
import { services } from '../../const';
import Service from '../service/service';
import 'swiper/css';
import 'swiper/css/navigation';
import styles from './services.module.scss';


SwiperCore.use([Autoplay, A11y, Navigation]);

function Services() {

  const isMaxDesktop = useMediaQuery({ query: '(min-width: 1300px)' });

  const isDesktop = useMediaQuery({ query: '(min-width: 1200px)' });

  const isTablet = useMediaQuery({
    query: '(min-width: 744px)',
  });

  return (
    <div className={styles.services} id="services">
      <h2 className={styles.title}>Основные виды массажа</h2>
      <Swiper
        // eslint-disable-next-line no-nested-ternary
        slidesPerView={isMaxDesktop ? 5 : isDesktop ? 4 : isTablet ? 3 : 1}
        spaceBetween={30}
        a11y
        loop
        navigation
      >
        {
          services.map((service) => (
            <SwiperSlide key={service.id}>
              <Service service={service} />
            </SwiperSlide>
          ))
        }
      </Swiper>
    </div >

  );
}

export default Services;
