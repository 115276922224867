import React from 'react';
import photo from '../../images/about.jpg';
import styles from './about.module.scss';

function About() {
  return (
    <div className={styles.container} id="about">
      <div className={styles.wrapper}>
        <h1 className='visually-hidden'>Турецкий массаж на дому</h1>
        <img className={styles.photo} src={photo} width="635" height="667" alt="Турецкий массаж от Мустафы"></img>
        <div className={styles.description}>
          <h2 className={styles.title}>Насладитесь турецким массажем!</h2>
          <span className={styles.border}></span>
          <ul className={styles.list}>
            <li className={styles.item}>Кая Мустафа (38 лет)</li>
            <li className={styles.item}>Опыт работы 18 лет</li>
            <li className={styles.item}>Образование средне-специальное по специальности массажист-косметолог</li>
            <li className={styles.item}>Окончил Анталийскую Школу Массажа в Турции</li>
            <li className={styles.item}>Работал 10 лет в лучших отелях г. Белек и г. Анталии</li>
            <li className={styles.item}>Переехал в Россию в 2013 году в связи с изменением семейного положения</li>
            <li className={styles.item}>Имеет все оформленные документы для проживания и работы в России</li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default About;
