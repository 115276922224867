import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import first from '../../images/otziv1.jpg';
import second from '../../images/otziv2.jpg';
import third from '../../images/otziv3.jpg';
import forth from '../../images/otziv4.jpg';
import fifth from '../../images/otziv5.jpg';
import sixth from '../../images/otziv6.jpg';
import seventh from '../../images/otziv7.jpg';
import eighth from '../../images/otziv8.jpg';
import ninth from '../../images/otziv9.jpg';
import tenth from '../../images/otziv10.jpg';
import eleven from '../../images/otziv11.jpg';
import twelve from '../../images/otziv12.jpg';
import thirteenth from '../../images/otziv13.jpg';
import styles from './review.module.scss';

const images = [first, thirteenth, second, third, forth, fifth, sixth, seventh, eighth, ninth, tenth, eleven, twelve];

function Review({ review }) {

  const { id, date, name, text, className } = review;

  return (
    <div className={cn(styles.container, className)}>
      <div className={styles.wrapper}>
        <p className={cn(styles.text, className)}>{text}</p>
        <div className={styles.author}>
          <p className={styles.date}>{date}</p>
          <p className={styles.name}>{name}</p>
        </div>
      </div>
      <img className={styles.image} src={images[id]} alt="Отзыв клиента" width="533" height="800" />
    </div>
  );
}

Review.propTypes = {
  review: PropTypes.shape({
    id: PropTypes.number.isRequired,
    date: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    className: PropTypes.string.isRequired,
  }),
};

export default Review;
