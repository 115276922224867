import React from 'react';
import photoBG from '../../images/video.jpg';
import video from '../../images/video.mp4';
import styles from './video.module.scss';

import { Player } from 'video-react';

function Video() {
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <h2 className='visually-hidden'>Видео массажа</h2>
        <div>
          <Player
            playsInline
            poster={photoBG}
          >
            <source src={video} />
          </Player>
        </div>
      </div>
    </div>
  );
}

export default Video;
