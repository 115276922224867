import React from 'react';
import PropTypes from 'prop-types';
import styles from './license.module.scss';

function License({ license }) {

  const { image, tabName } = license;

  return (
    <div className={styles.item}>
      <img className={styles.image} src={image} alt={`${tabName}`} width="1200" height="857" />
    </div>
  );
}

License.propTypes = {
  license: PropTypes.shape({
    id: PropTypes.number.isRequired,
    image: PropTypes.string.isRequired,
    tabName: PropTypes.string.isRequired,
  }),
};

export default License;
